/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from 'react'
import PageWrapper from './src/components/PageWrapper/PageWrapper'
import 'focus-visible';
import 'focus-within-polyfill';

export const wrapPageElement = ({element, props}) => (
	<PageWrapper {...props}>
		{element}
	</PageWrapper>
)