import React from 'react'
import Loader from '../Loader/Loader'

export default function PageWrapper({children}) {
	return(
		<>
			<Loader />
			{children}
		</>
	)
}