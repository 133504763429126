import React, {useState, useEffect} from 'react'
import {AnimatePresence, motion} from 'framer-motion'
import loaderImage from '../../assets/images/loader.gif'
import './Loader.scss'

export default function Loader() {

	const [intro, setIntro] = useState(false)

	useEffect(() => {
		setTimeout(() => {
			setIntro(true)
		}, 800)
	}, [])

	return (
		<div className="Loader-wrapper">
			<AnimatePresence>
				{
					!intro &&
					<motion.div
						className="Loader"
						initial={{opacity: 1}}
						animate={{opacity: 1}}
						exit={{opacity: 0, transition: {duration: 1}}}
					>
						<img src={loaderImage} alt="Lovdata Pro laster inn" loading="eager" />
					</motion.div>
				}
			</AnimatePresence>
		</div>
	)
}